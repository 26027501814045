import React from 'react';

const Footer = () => {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Creative Tech Forge</p>
      <div className="social-media">
        <a href="https://www.youtube.com/channel/UC6x-qmb5f_TcirIZqqqvC-Q" target="_blank" rel="noopener noreferrer">
          YouTube
        </a>
      
        <a href="https://www.tiktok.com/@YOUR_TIKTOK_ID" target="_blank" rel="noopener noreferrer">
          TikTok
        </a>
        <a href="https://www.facebook.com/YOUR_FACEBOOK_PAGE" target="_blank" rel="noopener noreferrer">
          Facebook
        </a>
        {/* Add more links as needed */}
      </div>
    </footer>
  );
};

export default Footer;
