import React from 'react';

const About = () => {
  return (
    <div className="container">
      <h1>About</h1>
      <img src="/about-image.jpg" alt="About" style={{ width: '100%', borderRadius: '10px', margin: '20px 0' }} />
      <h2>Learn more about Creative Tech Forge!</h2>
      <p>Creative Tech Forge is your go-to place for tech insights, game development, and more. Join us on this journey!</p>
    </div>
  );
};

export default About;
