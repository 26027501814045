import React from 'react';

const Games = () => {
  return (
    <div className="container">
      <h1>Games</h1>
      <img src="/games-image.jpg" alt="Games" style={{ width: '100%', borderRadius: '10px', margin: '20px 0' }} />
      <h2>Check out our latest games!</h2>
      <p>Explore our collection of fun and engaging games. More details coming soon!</p>
    </div>
  );
};

export default Games;
