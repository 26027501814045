import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
  return (
    <div className="container">
      <h1>Blog</h1>
      <img src="/blog-image.jpg" alt="Blog" style={{ width: '100%', borderRadius: '10px', margin: '20px 0' }} />
      <p>Coming soon: Articles on building with ChatGPT!</p>
      <h2>Read More:</h2>
      <ul>
        <li><Link to="/blog/game">How I Used ChatGPT to Create My Own Game</Link></li>
        <li><Link to="/blog/app">How I Built an App with ChatGPT</Link></li>
      </ul>
    </div>
  );
};

export default Blog;
