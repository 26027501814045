import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Blog from './pages/Blog';
import Games from './pages/Games';
import About from './pages/About';
import GameBlog from './pages/GameBlog';  // Import the game blog post
import AppBlog from './pages/AppBlog';      // Import the app blog post
import Header from './components/Header';
import Footer from './components/Footer';
import './styles/App.css';

const App = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/games" element={<Games />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog/game" element={<GameBlog />} /> // Route for game blog
        <Route path="/blog/app" element={<AppBlog />} />   // Route for app blog
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
