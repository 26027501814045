import React, { useEffect, useState } from 'react';

const Home = () => {
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=UC6x-qmb5f_TcirIZqqqvC-Q&key=AIzaSyDnYdYbi5cxZ4bta8PKV2JyQH4Bb9fF6dU`
        );
        const data = await response.json();
        setSubscriberCount(data.items[0].statistics.subscriberCount);
      } catch (error) {
        console.error("Error fetching subscriber count:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscribers();
  }, []);

  return (
    <div className="container">
      <div className="hero-section" style={{ backgroundImage: `url(/hero-image.jpg)` }}>
        <div className="hero-content">
          <h1>Welcome to Creative Tech Forge</h1>
          <h2>Your hub for tech, gaming, and creativity!</h2>
        </div>
      </div>
      
      <div className="subscriber-count">
        <h2>Join Our Community!</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <p>We currently have <strong>{subscriberCount}</strong> subscribers on YouTube!</p>
        )}
      </div>

      <div className="featured-videos">
        <h2>Featured Videos</h2>
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/T_7I7qVNQXQ" // Replace with your video ID
          title="Featured Video"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <p>Explore articles, tutorials, and games that inspire and entertain.</p>
    </div>
  );
};

export default Home;
